@font-face {
  font-family: Gilroy-Bold;
  src: url('../fonts/Gilroy-Bold.otf');
}

@font-face {
  font-family: AmaticSC;
  src: url('../fonts/AmaticSC-Regular.ttf');
}

@font-face {
  font-family: Fredoka;
  src: url('../fonts/Fredoka-VariableFont_wdth.ttf');
}

@font-face {
  font-family: Frank-Ruhl-Libre;
  src: url('../fonts/FrankRuhlLibre-Regular.ttf');
}

@font-face {
  font-family: Gilroy-Medium;
  src: url('../fonts/Gilroy-Medium.otf');
}

@font-face {
  font-family: Gilroy-Light;
  src: url('../fonts/Gilroy-Light.otf');
}

@font-face {
  font-family: LucidaGrande;
  src: url('../fonts/LucidaGrande.ttf');
}

@font-face {
  font-family: SFProText-Regular;
  src: url('../fonts/SFProText-Regular.otf');
}

@font-face {
  font-family: SFProText-Semibold;
  src: url('../fonts/SFProText-Semibold.otf');
}

@font-face {
  font-family: TTNorms-Medium;
  src: url('../fonts/TTNorms-Medium.otf');
}

@font-face {
  font-family: Segoe UI;
  src: url('../fonts/segoeui.ttf');
}

@font-face {
  font-family: UniSans;
  src: url('../fonts/Uni-Sans.otf');
}

@font-face {
  font-family: Arimo-Regular;
  src: url('../fonts/Arimo-Regular.ttf');
}
